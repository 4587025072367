module.exports = {
    language: {
        name: 'EN'
    },
    navbar: {
        home: "智云视图",
        about: "关于我们",
        service: "优势",
        portfolio: "产品",
        contact: "联系我们",
        github: "github开源"
    },
    homepage: {
        top_title: "我们专注于深度学习与计算机视觉技术创新，提供领先的视觉智能技术平台的人工智能解决方案！",
        button_title: "立即体验",
        description: "北京智云视图科技有限公司（Zeusee Technology）是一家专注于深度学习与计算机视觉技术创新的公司，通过国家高新技术企业认证，提供领先的视觉智能技术平台解决方案，具有扎实高效的工程架构能力及产品研发能力。",
        advantage: "我们的优势",
        advantage_title1: "技术",
        advantage_desc1: "拥有国内领先的检测定位、识别技术。系统架构支持分布式深度学习、多机多卡高并行，并且为客户提供优化部署模型，大大提高模型训练的速度个并降低部署成本。",
        advantage_title2: "经验",
        advantage_desc2: "专注计算机视觉与深度学习，在物体检测与识别领域拥有领先的技术与丰富的产品研发经验。车牌识别，人脸识别，安防监控等技术落地到应用，多元的产品包为各行各业的客户提供技术支持。",
        advantage_title3: "可靠",
        advantage_desc3: "支持高并发承载与高可用性保证。可提供企业级稳定、精确的大流量服务，拥有毫秒级识别响应能力、弹性灵活的高并发承载等全方位的可靠性服务保障。",
        advantage_title4: "性能",
        advantage_desc4: "利用深度学习技术及精准的算法迭代模型，准确性高，效果行业领先，为业务产品营造顺畅体验。多种服务形式全面开放，适配各种应用场景。",
    },
    production: {
        our_production: "我们的产品",
        our_production_en: "Our Poduction",
        face_recognition: "人脸识别",
        face_landmark: "人脸标定",
        lpr: "车牌识别",
        vehicle_recognition: "车型识别",
        live_detection: "活体检测",
        segmentation: "人像分割",
        hand_esture: "手势识别",
        ocr: "证件识别",
        face_recognition_en: "face recognition",
        face_landmark_en: "face landmark",
        lpr_en: "license plate recognition",
        vehicle_recognition_en: "vehicle type recognition",
        live_detection_en: "live detection",
        ocr_en: "OCR",
        detail: "点击体验"
    },
    bottom: {
        cooperation: "商务合作联系",
        follow: "关注智云",
        solution: "解决方案",
        company: "公司",
        try: "免费试用",
        contact: "联系方式",
        kefu1: "13261129380（客服）",
        email: "邮箱：contact@zeusee.com",
        copyright: "Copyright © 北京智云视图科技有限公司（Zeusee Technologies）",
        icp: "京ICP备17055069号-1",
        face_recognition: "人脸识别",
        face_landmark: "人脸标定",
        lpr: "车牌识别",
        vehicle_recognition: "车型识别",
        live_detection: "活体检测",
        ocr: "证件识别",
        segmentation: "人像分割",
        hand_esture: "手势识别",
    },
    faceR: {
        desc: "人脸识别是一种基于人的脸部特征信息进行身份认证的生物特征识别技术。它集成了人工智能、机器识别、机器学习、模型理论、视频图像处理等多种专业技术。我们利用深度学习来进行人脸综合算法与应用的设计，包含了人脸检测、人脸实时关键点检测、人脸识别等多个功能。",
        demo: "功能演示",
        similarity: "相似度",
        file: "选择文件",
        result: "识别结果",
        scene: "应用场景",
        scene1: "人脸支付",
        scene2: "美颜",
        scene3: "无人零售",
        advantage_title1: "高性能",
        advantage_desc1: "拥有千万级训练数据集，适应真实场景下的识别，包括多人场景、遮挡、不当光照等。",
        advantage_title2: "低延迟",
        advantage_desc2: "在移动设备上轻松处理静态图片或视频流，告别延迟卡顿。",
        advantage_title3: "应用广泛",
        advantage_desc3: "目前已在智能门禁、手机解锁、人脸支付、特殊人物识别等方面有广泛应用。",
    },
    faceL: {
        desc: "我们利用深度学习来进行人脸综合算法与应用的设计，包含了人脸检测、人脸实时关键点检测、人脸识别等多个功能。人脸标定可以精确定位面部的关键区域位置，包括眉毛、眼睛、鼻子、嘴巴，脸部轮廓等，并能实现动态跟踪。可以在各种表情、姿态、遮挡模糊状态下，对人脸进行精准定位。使用关键点检测技术，可以精确定位人脸美化局部，还可以实现表情交互。",
        intro: "功能介绍",
        scene: "应用场景",
        scene1: "3D建模",
        scene2: "动态贴纸",
        scene3: "美颜",
        advantage_title1: "106个关键点",
        advantage_desc1: "高达 106 点的高精度人脸关键点，完美贴合人脸。完成人脸美颜、贴纸、模型，人脸图像的精细化处理。",
        advantage_title2: "高性能",
        advantage_desc2: "无论是静态图片或视频流都可以在移动设备上轻松处理。显示流畅，无卡顿。",
        advantage_title3: "准确度高",
        advantage_desc3: "适应人脸倾斜，支持视频和图片中多人脸识别和标定。",
    },
    lpr: {
        desc: "车牌识别是计算机视频图像识别技术在车辆牌照识别中的一种应用。在机器学习算法的驱动下，可以做到对车辆车牌信息进行检测和识别，应用范围包括卡口摄像机，停车场等。",
        demo: "功能演示",
        file: "选择文件",
        result: "识别结果",
        scene: "应用场景",
        scene1: "远程验证车牌",
        scene2: "智能停车场",
        scene3: "智能交通",
        advantage_title1: "高性能",
        advantage_desc1: "适应真实场景下的识别，包括遮挡、不当光照、倾斜等。",
        advantage_title2: "准确度高",
        advantage_desc2: "强大的深度学习算法，能精准识别图片中的车牌位置及内容。",
        advantage_title3: "快速高效",
        advantage_desc3: "毫秒级时间即可返回识别结果。",
    },
    vtype: {
        desc: "识别车辆的具体车型，输出图片中主体车辆的品牌、型号、年份、颜色。在机器学习算法的驱动下，可以做到对车辆一一些信息检测和识别，应用范围包括卡口摄像机，停车场等。",
        demo: "功能演示",
        file: "选择文件",
        result: "识别结果",
        scene: "应用场景",
        scene1: "智能交通",
        scene2: "拍图识车",
        advantage_title1: "品牌识别",
        advantage_desc1: "可识别4000种以上汽车子品牌、年款。",
        advantage_title2: "车型识别",
        advantage_desc2: "可识别16种细分车型。",
        advantage_title3: "车身颜色",
        advantage_desc3: "可识别多种车身颜色。",
        advantage_title4: "标志物检测",
        advantage_desc4: "可检测年检标、遮阳板、纸巾盒、挂件等。",
    },
    live: {
        desc: "我们利用深度学习来进行人脸综合算法与应用的设计，包含了人脸检测、人脸实时关键点检测、人脸识别、活体检测等多个功能。活体识别是基于人类自然的眨眼睛动作检测，配合上下左右摆头，可有效分辨照片和活体，避免通过使用照片或者视频流“蒙骗”识别系统来获得验证通过，提高系统安全性。",
        intro: "功能介绍",
        scene: "应用场景",
        scene1: "人证核验",
        scene2: "人脸支付",
        advantage_title1: "兼容性",
        advantage_desc1: "支持多平台，完美兼容市场主流镜头。",
        advantage_title2: "准确率高",
        advantage_desc2: "对人脸视频能进行严格校验识别，识别准确率高。",
        advantage_title3: "高性能",
        advantage_desc3: "低功耗低CPU占用率，可以在移动设备上轻松准确处理",
    },
    ocr: {
        desc: "我们利用深度学习来进行支持对二代居民身份证正反面、银行卡号码、名片等进行结构化识别，包括姓名、身份证号、生日、手机号等关键字段，识别准确率超过99%。",
        intro: "功能介绍",
        scene: "应用场景",
        scene1: "身份认证",
        scene2: "支付绑卡",
        scene3: "征信评估",
        advantage_title1: "字段齐全",
        advantage_desc1: "支持对多个字段的结构化识别，能够实现不同业务场景下对任意字段信息的提取，满足各类相关需求",
        advantage_title2: "准确率高",
        advantage_desc2: "对各类证件能进行严格校验识别，识别准确率高。",
        advantage_title3: "高性能",
        advantage_desc3: "低功耗低CPU占用率，可以在移动设备上轻松准确处理",
    },
}
