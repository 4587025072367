module.exports = {
    language: {
        name: '中文'
    },
    navbar: {
        home: "ZEUSEE",
        about: "ABOUT",
        service: "TECHNOLOGIES",
        portfolio: "PRODUCT",
        contact: "CONTACT",
        github: "GITHUB"
    },
    homepage: {
        top_title: "We focus on deep learning and innovation of computer vision technology, and provide leading visual technology platform for artificial intelligence solutions!",
        button_title: "Try Now",
        description: "Beijing Zeusee Technology Co., Ltd. is an artificial intelligence company, which focuses on deep learning and computer vision technology innovation, and provides a leading visual technology platform. We have top-ranking research and development team and deep learning algorithm, which allows you to add leading, deep learning-based image analysis recognition technologies into your applications, with simple and powerful SDKs.",
        advantage: "Technologies",
        advantage_title1: "Technologies",
        advantage_desc1: "We have the leading technology of detection, location and identification, which supports distributed deep learning and high concurrency. We provides optimized deployment model for partners, which greatly improves the speed of model training and reduces the cost of deployment.",
        advantage_title2: "Experience",
        advantage_desc2: "We focus on computer vision and deep learning, and have the leading technology and rich research and development experience in the field of object detection and recognition. We provide license plate recognition, face recognition, security monitoring and other applications, and technical support of multiple products for customers.",
        advantage_title3: "Reliability",
        advantage_desc3: "We provide service with high concurrent and high availability assurance, and also provide stable and accurate service in enterprise level, with millisecond recognition response ability, flexible and high concurrent capacity.",
        advantage_title4: "Performance",
        advantage_desc4: "We use deep learning technology, accurate algorithm model and rich experience for business products to achieve high accuracy. We provide various forms of services and fully adaptable to various application scenarios.",
    },
    production: {
        our_production: "Our Poduction",
        our_production_en: "",
        face_recognition_en: "Face Recognition",
        face_landmark_en: "Face Landmark",
        lpr_en: "License Plate Recognition",
        vehicle_recognition_en: "Vehicle Type Recognition",
        live_detection_en: "Live Detection",
        ocr_en: "OCR",
        face_recognition: "",
        face_landmark: "",
        lpr: "",
        vehicle_recognition: "",
        live_detection: "",
        ocr: "",
        detail: "More"
    },
    bottom: {
        cooperation: "Business Contact",
        follow: "Follow Us",
        solution: "Product",
        company: "Resources",
        try: "Get Started",
        contact: "Contact Us",
        kefu1: "+86-13261129380",
        email: "contact@zeusee.com",
        copyright: "Copyright © Beijing Zeusee Technology Co., Ltd",
        icp: "京ICP备17055069号-1",
        face_recognition: "Face Recognition",
        face_landmark: "Face Landmark",
        lpr: "License Plate Recognition",
        vehicle_recognition: "Vehicle Type Recognition",
        live_detection: "Live Detection",
        ocr: "OCR",
    },
    faceR: {
        desc: "Face recognition is a biometrics technology based on human face information for identification. It integrates artificial intelligence, machine recognition, machine learning, model theory, image processing and other professional technologies. We use deep learning to design face recogition algorithm and application, including face detection, face landmark, face recognition and other technologies.",
        demo: "Demo",
        similarity: "Similarity",
        file: "File",
        result: "Result",
        scene: "Scenarios",
        scene1: "Face Recognition Payment",
        scene2: "Beauty Camera",
        scene3: "Unmanned Supermarket",
        advantage_title1: "High Performance",
        advantage_desc1: "We have tens of thousands of training data sets, and apply to the recognition of real scenes, such as multi-player scenes, occlusion, improper lighting, etc.",
        advantage_title2: "Low Latency",
        advantage_desc2: "Easily deal with static images or video streams on mobile devices without any delay.",
        advantage_title3: "Wide Application",
        advantage_desc3: "At present, it has been widely used in intelligent access control, mobile phone unlock, face recognition payment, special character recognition and other aspects.",
    },
    faceL: {
        desc: "We use deep learning to design face algorithm and application, including face detection, face landmark, face recognition, live detection and other functions. Face landmark can accurately locate the key regions of the face, including eyebrows, eyes, nose, mouth, face contour and so on, thereby realize dynamic tracking. It can accurately locate the face in various expressions, gestures and occlusion blurred states, and realize face beauty and expression interaction.",
        intro: "Introduction",
        scene: "Scenarios",
        scene1: "3D Modeling",
        scene2: "Dynamic Stickers",
        scene3: "Beauty Camera",
        advantage_title1: "106 key points",
        advantage_desc1: "High-precision 106-point landmarks which fit the face perfectly, and remain stable against video jitter, then finish the fine processing of face beauty, stickers and other effects.",
        advantage_title2: "High Performance",
        advantage_desc2: "Easily deal with static images or video streams on mobile devices without any delay.",
        advantage_title3: "High Precision",
        advantage_desc3: "The landmark model adapts to face tilt and supports multi-face location and recognition in images and videos.",
    },
    lpr: {
        desc: "License plate recognition is an application of computer image recognition technology. Vehicle license plate information can be detected and recognized through machine learning algorithm, and applied to the fields such as surveillance cameras, parking lots and so on.",
        demo: "Demo",
        file: "File",
        result: "Result",
        scene: "Scenarios",
        scene1: "License Plate Verification",
        scene2: "Intelligent Parking",
        scene3: "Intelligent Transportation",
        advantage_title1: "High Performance",
        advantage_desc1: "It is suitable for real scene recognition, including occlusion, illumination, tilt and so on.",
        advantage_title2: "High Accuracy",
        advantage_desc2: "Accurately recognize the license plate location and content with powerful deep learning algorithm.",
        advantage_title3: "Efficient",
        advantage_desc3: "The recognition results are returned in millisecond time.",
    },
    vtype: {
        desc: "Vehicle type recognition refers to recognize the information such as the brand, model, year and color of vehicles in image or video using machine learning algorithm. Its application scope includes surveillance cameras, parking lots and so on.",
        demo: "Demo",
        file: "File",
        result: "Result",
        scene: "Scenarios",
        scene1: "Intelligent Transportation",
        scene2: "Vehicle Recognition",
        advantage_title1: "Brand",
        advantage_desc1: "Recognize more than 4000 vehicle brands.",
        advantage_title2: "Vehicle Model",
        advantage_desc2: "Identify 16 subdivision models.",
        advantage_title3: "Color",
        advantage_desc3: "Recognize various vehicle body colors.",
        advantage_title4: "Landmark",
        advantage_desc4: "Detect annual inspection, sunshade board, tissue box, pendant, etc.",
    },
    live: {
        desc: "We use deep learning to design face algorithm and application, including face detection, face key point detection, face recognition, live detection and other functions. Live recognition is based on the detection of human natural blinking movement, nodding up and down or shaking left and right, which can effectively distinguish the photos from the living bodies, avoiding pass system validation through videos and improving the security of the system.",
        intro: "Introduction",
        scene: "Scenarios",
        scene1: "Face Verification",
        scene2: "Face Recognition Payment",
        advantage_title1: "Compatibility",
        advantage_desc1: "Support multiple platforms, perfectly compatible with popular cameras in the market.",
        advantage_title2: "High Accuracy",
        advantage_desc2: "Videos can be strictly verified and recognized with high recognition accuracy.",
        advantage_title3: "High Performance",
        advantage_desc3: "It is easy and accurate processing on mobile devices with low power consumption and low CPU usage.",
    },
    ocr: {
        desc: "We use deep learning to support the structural identification of the second-generation resident identity CARDS, bank card Numbers, business CARDS and other key fields, including name, id number, birthday, mobile phone number, etc., with an identification accuracy of more than 99%.",
        intro: "Introduction",
        scene: "Scenarios",
        scene1: "Identity Authentication",
        scene2: "Binding cards",
        scene3: "Credit Evaluation",
        advantage_title1: "Complete",
        advantage_desc1: "Support the structural recognition of multiple fields, can realize the extraction of arbitrary field information under different business scenarios, meet all kinds of related requirements",
        advantage_title2: "High Accuracy",
        advantage_desc2: "All kinds of certificates can be strictly verified and identified, and the identification accuracy is high.",
        advantage_title3: "High Performance",
        advantage_desc3: "It is easy and accurate processing on mobile devices with low power consumption and low CPU usage.",
    },
}
