import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min'
import './assets/css/fonts.css'
import './assets/css/style.css'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: window.localStorage.getItem('lang') || "cn",    // 语言标识
  messages: {
    'cn': require('./util/lang/cn'),   // 中文语言包
    'en': require('./util/lang/en')    // 英文语言包
  },
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
