<template>
    <footer class="section footer-2 context-dark">
        <div class="container">
          <div class="row row-40">
            <div class="col-md-6 col-lg-3">
              <h5 class="title">产品</h5>
              <ul class="contact-box">
                <li><router-link to="/face-landmark">人脸标定</router-link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<router-link to="/lpr">车牌识别</router-link></li>
                <li><router-link to="/segmentation">人像分割</router-link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<router-link to="/vehicle-recognition">车型识别</router-link></li>
                <li><router-link to="/live-detection">活体检测</router-link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<router-link to="/gesture">手势识别</router-link></li>
              </ul>
            </div>
            <div class="col-md-6 col-lg-2">
              <h5 class="title">智能平台</h5>
              <ul class="contact-box">
                <li><router-link to="/face-landmark">行人属性识别系统</router-link></li>
                <li><router-link to="/lpr">车辆大数据结构化系统</router-link></li>
              </ul>
              <!-- <img src="@/assets/images/qrcode.jpg" width="120px" height="120px"/> -->
            </div>
            <div class="col-md-6 col-lg-2">
              <h5 class="title">技术支持</h5>
              <ul class="contact-box">
                <li><a href="https://github.com/zeusees">Github</a></li>
                <li><a href="https://blog.csdn.net/zeusee">CSDN</a></li>
                <li><a href="https://www.zhihu.com/org/zhi-yun-shi-tu">知乎</a></li>
              </ul>
            </div>
            <div class="col-md-6 col-lg-3">
              <h5 class="title">关于我们</h5>
              <ul class="contact-box">
                <li><router-link to="/about">认识我们</router-link></li>
                <!-- <li><router-link to="/lpr">资质荣誉</router-link></li> -->
              </ul>
            </div>
            <div class="col-md-6 col-lg-2">
              <h5 class="title" style="text-align: left">联系我们</h5>
              <ul class="contact-box">
                <!-- <li>
                  <div class="unit unit-horizontal unit-spacing-xxs">
                    <div class="unit-left"><span class="icon mdi mdi-map-marker"></span></div>
                    <div class="unit-body"><a href="#">2130 Fulton Street, <br class="veil reveal-lg-inline">San Diego, CA 94117-1080 USA</a></div>
                  </div>
                </li> -->
                <li>
                  <div class="unit unit-horizontal unit-spacing-xxs">
                    <div class="unit-left"><span class="icon mdi mdi-phone"></span></div>
                    <div class="unit-body">
                      <ul class="list-phones">
                        <li><a href="tel:#">13261129380（微信同号）</a></li>
                        <li><a href="tel:#">13072090611（微信同号）</a></li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="unit unit-horizontal unit-spacing-xxs">
                    <div class="unit-left"><span class="icon mdi mdi-email-outline"></span></div>
                    <div class="unit-body"><a href="mailto:#">contact@zeusee.com</a></div>
                  </div>
                </li>
              </ul>
              <!-- <div class="group-md group-middle social-items"><a class="icon icon-md novi-icon mdi mdi-github-circle" href="https://github.com/zeusee"></a><a class="icon icon-md novi-icon mdi mdi-instagram" href="https://blog.csdn.net/zeusee"></a><a class="icon icon-md novi-icon mdi mdi-facebook-messenger" href="https://www.zhihu.com/org/zhi-yun-shi-tu"></a></div> -->
            </div>
          </div>
          <!-- Rights-->
          <p class="rights">Copyright © 北京智云视图科技有限公司（Zeusee Technology）</p> <p id="cnzz"></p>
          <p class="rights" style="margin-top:10px"><a href="http://www.beian.miit.gov.cn"><img src="@/assets/images/license.png" alt=""/>京ICP备17055069号-1</a></p>
        </div>
    </footer>
</template>

<script>
    export default {
        name: 'Bottom',
        props: {

        },
        mounted() {
          let div = document.getElementById("cnzz");
          let script = document.createElement("script");
          script.src = "https://s9.cnzz.com/z_stat.php?id=1280738974&web_id=1280738974";
          div.appendChild(script);
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    a {
        text-decoration: none;
    }
</style>
