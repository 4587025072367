<template>
  <div class="home">
    <Header/>
    <!-- Swiper-->
    <section class="section swiper-container swiper-slider swiper-slider-2 swiper-bg" data-loop="true" data-autoplay="false" data-simulate-touch="false" data-slide-effect="fade">
      <div class="swiper-wrapper text-center text-md-left">
        <div class="swiper-slide position-relative">
          <div class="swiper-slide-caption">
            <div class="container">
              <div class="row">
                <div class="col-md-9 col-lg-7">
                  <h1 data-caption-animate="fadeInUp" data-caption-delay="100">智云视图</h1><a class="button button-lg button-secondary" href="/#about" data-caption-animate="fadeInUp" data-caption-delay="150">了解更多</a>
                </div>
                <div class="swiper-img-block">
                  <div class="swiper-img-block-inner">
                    <div class="img-block-inner-item"><img src="@/assets/images/main-slide-1.png" alt=""></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="swiper-slide position-relative">
          <div class="swiper-slide-caption">
            <div class="container">
              <div class="row">
                <div class="col-md-9 col-lg-7">
                  <h1 data-caption-animate="fadeInUp" data-caption-delay="100">Affordable<br>domains</h1><a class="button button-lg button-secondary" href="/#about" data-caption-animate="fadeInUp" data-caption-delay="150">Learn More</a>
                </div>
                <div class="swiper-img-block">
                  <div class="swiper-img-block-inner">
                    <div class="img-block-inner-item"><img src="@/assets/images/main-slide-2.png" alt=""></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <div class="swiper-slide position-relative">
          <div class="swiper-slide-caption">
            <div class="container">
              <div class="row">
                <div class="col-md-9 col-lg-7">
                  <h1 data-caption-animate="fadeInUp" data-caption-delay="100">Total security<br> reliability</h1><a class="button button-lg button-secondary" href="/#about" data-caption-animate="fadeInUp" data-caption-delay="150">Learn More</a>
                </div>
                <div class="swiper-img-block">
                  <div class="swiper-img-block-inner">
                    <div class="img-block-inner-item"><img src="@/assets/images/main-slide-3.png" alt=""></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="swiper-meta">
        <!-- <ul class="links">
          <li><a class="icon icon-meta mdi mdi-github-circle" href="https://github.com/zeusee"></a></li>
          <li><a class="icon icon-meta mdi mdi-instagram" href="https://blog.csdn.net/zeusee"></a></li>
          <li><a class="icon icon-meta mdi mdi-facebook-messenger" href="https://www.zhihu.com/org/zhi-yun-shi-tu"></a></li>
        </ul> -->
        <!-- <div class="contacts">
          <div class="icon mdi mdi-cellphone-iphone"></div>
          <div class="tel"><a href="tel:#">1-800-1234-567</a></div>
          <div class="request"><a href="#">Request a Call Back</a></div>
        </div> -->
        <!-- Swiper Pagination-->
        <div class="swiper-pagination"></div>
      </div>
    </section>
    <!-- Advantages-->
    <section class="section context-dark">
      <div class="row row-flex no-gutters">
        <div class="col-md-6 col-lg-3" style="padding-right: 0;padding-left: 0;">
          <div class="blurb-boxed-2">
            <h6 class="title">技术</h6>
            <p class="exeption">拥有国内领先的检测定位、识别技术。系统架构支持分布式深度学习、多机多卡高并行，并且为客户提供优化部署模型，大大提高模型训练的速度个并降低部署成本。</p>
          </div>
        </div>
        <div class="col-md-6 col-lg-3" style="padding-right: 0;padding-left: 0;">
          <div class="blurb-boxed-2 blurb-boxed-dark">
            <h6 class="title">经验</h6>
            <p class="exeption">专注计算机视觉与深度学习，在物体检测与识别领域拥有领先的技术与丰富的产品研发经验。车牌识别，人脸识别，安防监控等技术落地到应用，多元的产品包为各行各业的客户提供技术支持。</p>
          </div>
        </div>
        <div class="col-md-6 col-lg-3" style="padding-right: 0;padding-left: 0;">
          <div class="blurb-boxed-2 blurb-boxed-darker">
            <h6 class="title">可靠</h6>
            <p class="exeption">支持高并发承载与高可用性保证。可提供企业级稳定、精确的大流量服务，拥有毫秒级识别响应能力、弹性灵活的高并发承载等全方位的可靠性服务保障。</p>
          </div>
        </div>
        <div class="col-md-6 col-lg-3" style="padding-right: 0;padding-left: 0;">
          <div class="blurb-boxed-2 blurb-boxed-darkest">
            <p class="exeption">性能</p>
            <p class="exeption">利用深度学习技术及精准的算法迭代模型，准确性高，效果行业领先，为业务产品营造顺畅体验。多种服务形式全面开放，适配各种应用场景。</p>
          </div>
        </div>
      </div>
    </section>
    <!-- The Best Banking Choise-->
    <section class="section section-lg section-decorate">
      <div class="container">
        <div class="block-lg text-center">
          <h2>我们的产品</h2>
        </div>
        <div class="row row-30 row-xxl-60">
          <div class="col-sm-6 col-md-4 wow fadeInLeft" data-wow-delay="0.1s">
            <div class="blurb-image">
              <div class="icon"><a href="/#/face-landmark"><img src="@/assets/images/home/2.png" alt=""></a></div>
              <h6 class="title">人脸标定</h6>
              <p>Face Landmark</p>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 wow fadeInLeft" data-wow-delay="0.2s">
            <div class="blurb-image">
              <div class="icon"><a href="/#/lpr"><img src="@/assets/images/home/3.png" alt=""></a></div>
              <h6 class="title">车牌识别</h6>
              <p>License Plate Recognition</p>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 wow fadeInLeft">
            <div class="blurb-image">
              <div class="icon"><a href="/#/segmentation"><img src="@/assets/images/home/1.png" alt=""></a></div>
              <h6 class="title">人像分割</h6>
              <p>Human Body Segmentation</p>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 wow fadeInLeft" data-wow-delay="0.1s">
            <div class="blurb-image">
              <div class="icon"><a href="/#/vehicle-recognition"><img src="@/assets/images/home/4.png" alt=""></a></div>
              <h6 class="title">车型识别</h6>
              <p>Vehicle Type Recognition</p>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 wow fadeInLeft" data-wow-delay="0.2s">
            <div class="blurb-image">
              <div class="icon"><a href="/#/live-detection"><img src="@/assets/images/home/5.png" alt=""></a></div>
              <h6 class="title">活体检测</h6>
              <p>Live Detection</p>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 wow fadeInLeft" data-wow-delay="0.3s">
            <div class="blurb-image">
              <div class="icon"><a href="/#/gesture"><img src="@/assets/images/home/6.png" alt=""></a></div>
              <h6 class="title">手势识别</h6>
              <p>Hand Gesture Recognition</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- How to Order a New Card-->
    <section class="section section-lg section-decorate section-decorate-1 bg-gray-100">
      <div class="container text-center">
        <h2>智能平台</h2>
        <div class="row row-40 justify-content-center number-counter">
          <div class="col-sm-1 col-lg-2 wow fadeInLeft">
            <!-- <div class="blurb-icon-fill">
              <div class="icon"><img src="@/assets/images/icons/shared-hosting.svg" alt=""><span class="index-counter"></span></div>
              <h5 class="title">Shared hosting</h5>
              <p class="exeption">Shared hosting is the easiest, most economical way to get your website connected to the Internet.</p>
            </div> -->
          </div>
          <div class="col-sm-4 col-lg-3 wow fadeInLeft">
            <div class="blurb-icon-fill">
              <div><img src="@/assets/images/home/image-1.png" alt=""><!--<span class="index-counter"></span>--></div>
              <h5 class="title">行人属性识别系统</h5>
              <!-- <p class="exeption">行人属性识别系统</p> -->
            </div>
          </div>
          <div class="col-sm-1 col-lg-2 wow fadeInLeft">
          </div>
          <div class="col-sm-4 col-lg-3 wow fadeInLeft">
            <div class="blurb-icon-fill">
              <div><img src="@/assets/images/home/image-2.png" alt=""><!--<span class="index-counter"></span>--></div>
              <h5 class="title">车辆大数据结构化系统</h5>
              <!-- <p class="exeption">车辆大数据结构化系统</p> -->
            </div>
          </div>
          <div class="col-sm-1 col-lg-2 wow fadeInLeft">
            <!-- <div class="blurb-icon-fill">
              <div class="icon"><img src="@/assets/images/icons/cloud-hosting.svg" alt=""><span class="index-counter"></span></div>
              <h5 class="title">Cloud hosting</h5>
              <p class="exeption">Cloud hosting makes websites accessible using cloud resources.</p>
            </div> -->
          </div>
        </div>
      </div>
    </section>
    <Bottom/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Bottom from "@/components/Bottom";
import initFunc from '@/assets/js/script'
// import Swiper from 'swiper';
// import 'swiper/css';

export default {
  name: 'Home',
  mounted() {
    initFunc()
    // new Swiper('.swiper', {
    //   // Optional parameters
    //   direction: 'vertical',
    //   loop: true,

    //   // If we need pagination
    //   pagination: {
    //     el: '.swiper-pagination',
    //   },

    //   // Navigation arrows
    //   navigation: {
    //     nextEl: '.swiper-button-next',
    //     prevEl: '.swiper-button-prev',
    //   },

    //   // And if we need scrollbar
    //   scrollbar: {
    //     el: '.swiper-scrollbar',
    //   },
    // });
  },
  components: {
    Bottom, Header
  }
}
</script>

<style scoped>
    a {
        text-decoration: none;
    }
</style>
