<template>
    <header class="section page-header">
        <!-- RD Navbar-->
        <div class="rd-navbar-wrap rd-navbar-absolute">
          <nav class="rd-navbar rd-navbar-creative" data-layout="rd-navbar-fixed" data-sm-layout="rd-navbar-fixed" data-md-layout="rd-navbar-fixed" data-lg-layout="rd-navbar-fixed" data-xl-layout="rd-navbar-static" data-xxl-layout="rd-navbar-static" data-md-device-layout="rd-navbar-fixed" data-lg-device-layout="rd-navbar-fixed" data-xl-device-layout="rd-navbar-static" data-xxl-device-layout="rd-navbar-static" data-lg-stick-up-offset="20px" data-xl-stick-up-offset="20px" data-xxl-stick-up-offset="20px" data-lg-stick-up="true" data-xl-stick-up="true" data-xxl-stick-up="true">
            <div class="rd-navbar-main-outer">
              <div class="rd-navbar-main">
                <!-- RD Navbar Panel-->
                <div class="rd-navbar-panel">
                  <!-- RD Navbar Toggle-->
                  <button class="rd-navbar-toggle" data-rd-navbar-toggle=".rd-navbar-nav-wrap"><span></span></button>
                  <!-- RD Navbar Brand-->
                  <div class="rd-navbar-brand">
                    <!--Brand--><a class="brand" href="/index.html"><img class="brand-logo-dark" src="@/assets/images/home/logo-1.png" alt="" width="200" height="36"/><img class="brand-logo-light" src="@/assets/images/home/logo-2.png" alt="" width="200" height="36"/></a>
                  </div>
                </div>
                <div class="rd-navbar-main-element">
                  <div class="rd-navbar-nav-wrap">
                    <!-- RD Navbar Nav-->
                    <ul class="rd-navbar-nav">
                      <li class="rd-nav-item active"><a class="rd-nav-link" href="/index.html">首页</a>
                      </li>
                      <li class="rd-nav-item"><a class="rd-nav-link" href="/#/face-landmark">核心产品</a>
                        <!-- RD Navbar Dropdown-->
                        <ul class="rd-menu rd-navbar-dropdown">
                          <li class="rd-dropdown-item"><a class="rd-dropdown-link" href="/#/face-landmark">人脸标定</a></li>
                          <li class="rd-dropdown-item"><a class="rd-dropdown-link" href="/#/lpr">车牌识别</a></li>
                          <li class="rd-dropdown-item"><a class="rd-dropdown-link" href="/#/segmentation">人像分割</a></li>
                          <li class="rd-dropdown-item"><a class="rd-dropdown-link" href="/#/vehicle-recognition">车型识别</a></li>
                          <li class="rd-dropdown-item"><a class="rd-dropdown-link" href="/#/live-detection">活体检测</a></li>
                          <li class="rd-dropdown-item"><a class="rd-dropdown-link" href="/#/gesture">手势识别</a></li>
                        </ul>
                      </li>
                      <!-- <li class="rd-nav-item"><a class="rd-nav-link" href="careers.html">Careers</a>
                        <ul class="rd-menu rd-navbar-dropdown">
                          <li class="rd-dropdown-item"><a class="rd-dropdown-link" href="single-job.html">Single job</a></li>
                        </ul>
                      </li> -->
                      <!-- <li class="rd-nav-item"><a class="rd-nav-link" href="#">新闻中心</a>
                        <ul class="rd-menu rd-navbar-megamenu">
                          <li class="rd-megamenu-item">
                            <h6 class="rd-megamenu-title">Elements</h6>
                            <ul class="rd-megamenu-list">
                              <li class="rd-megamenu-list-item"><a class="rd-megamenu-list-link" href="typography.html">Typography</a></li>
                              <li class="rd-megamenu-list-item"><a class="rd-megamenu-list-link" href="buttons.html">Buttons</a></li>
                              <li class="rd-megamenu-list-item"><a class="rd-megamenu-list-link" href="forms.html">Forms</a></li>
                              <li class="rd-megamenu-list-item"><a class="rd-megamenu-list-link" href="tabs-and-accordions.html">Tabs and accordions</a></li>
                              <li class="rd-megamenu-list-item"><a class="rd-megamenu-list-link" href="progress-bars.html">Progress bars</a></li>
                              <li class="rd-megamenu-list-item"><a class="rd-megamenu-list-link" href="grid.html">Grid</a></li>
                              <li class="rd-megamenu-list-item"><a class="rd-megamenu-list-link" href="tables.html">Tables</a></li>
                            </ul>
                          </li>
                          <li class="rd-megamenu-item">
                            <h6 class="rd-megamenu-title">Blog</h6>
                            <ul class="rd-megamenu-list">
                              <li class="rd-megamenu-list-item"><a class="rd-megamenu-list-link" href="classic-blog.html">Classic blog</a></li>
                              <li class="rd-megamenu-list-item"><a class="rd-megamenu-list-link" href="grid-blog.html">Grid blog</a></li>
                              <li class="rd-megamenu-list-item"><a class="rd-megamenu-list-link" href="masonry-blog.html">Masonry blog</a></li>
                              <li class="rd-megamenu-list-item"><a class="rd-megamenu-list-link" href="modern-blog.html">Modern blog</a></li>
                              <li class="rd-megamenu-list-item"><a class="rd-megamenu-list-link" href="single-post.html">Single post</a></li>
                            </ul>
                          </li>
                          <li class="rd-megamenu-item">
                            <h6 class="rd-megamenu-title">Gallery</h6>
                            <ul class="rd-megamenu-list">
                              <li class="rd-megamenu-list-item"><a class="rd-megamenu-list-link" href="grid-gallery.html">Grid gallery</a></li>
                              <li class="rd-megamenu-list-item"><a class="rd-megamenu-list-link" href="masonry-gallery.html">Masonry gallery</a></li>
                            </ul>
                          </li>
                          <li class="rd-megamenu-item">
                            <h6 class="rd-megamenu-title">Extras</h6>
                            <ul class="rd-megamenu-list">
                              <li class="rd-megamenu-list-item"><a class="rd-megamenu-list-link" href="404.html">404 Page</a></li>
                              <li class="rd-megamenu-list-item"><a class="rd-megamenu-list-link" href="503.html">503 Page</a></li>
                              <li class="rd-megamenu-list-item"><a class="rd-megamenu-list-link" href="coming-soon.html">Coming soon</a></li>
                              <li class="rd-megamenu-list-item"><a class="rd-megamenu-list-link" href="login.html">Login/Registration</a></li>
                              <li class="rd-megamenu-list-item"><a class="rd-megamenu-list-link" href="privacy-policy.html">Privacy policy</a></li>
                              <li class="rd-megamenu-list-item"><a class="rd-megamenu-list-link" href="search-results.html">Search results</a></li>
                            </ul>
                          </li>
                        </ul>
                      </li> -->
                      <li class="rd-nav-item"><a class="rd-nav-link" href="index.html#/about">智能平台</a>
                        <!-- RD Navbar Dropdown-->
                        <ul class="rd-menu rd-navbar-dropdown">
                          <li class="rd-dropdown-item"><a class="rd-dropdown-link" href="/">行人属性识别系统</a></li>
                          <li class="rd-dropdown-item"><a class="rd-dropdown-link" href="/">车辆大数据结构化系统</a></li>
                        </ul>
                      </li>
                      <li class="rd-nav-item"><a class="rd-nav-link" href="index.html#/about">关于我们</a>
                        <!-- RD Navbar Dropdown-->
                        <!-- <ul class="rd-menu rd-navbar-dropdown">
                          <li class="rd-dropdown-item"><a class="rd-dropdown-link" href="index.html#/honor">荣誉资质</a></li>
                          <li class="rd-dropdown-item"><a class="rd-dropdown-link" href="index.html#/about">关于我们</a></li>
                        </ul> -->
                      </li>
                    </ul>
                  </div>
                  <!-- RD Navbar Search-->
                  <!-- <div class="rd-navbar-search">
                    <button class="rd-navbar-search-toggle rd-navbar-fixed-element-1" data-rd-navbar-toggle=".rd-navbar-search"><span></span></button>
                    <form class="rd-search" action="search-results.html" data-search-live="rd-search-results-live" method="GET">
                      <div class="form-wrap">
                        <label class="form-label" for="rd-navbar-search-form-input">Search</label>
                        <input class="rd-navbar-search-form-input form-input" id="rd-navbar-search-form-input" type="text" name="s" autocomplete="off">
                        <div class="rd-search-results-live" id="rd-search-results-live"></div>
                      </div>
                      <button class="rd-search-form-submit mdi mdi-magnify" type="submit"></button>
                    </form>
                  </div> -->
                </div>
              </div>
            </div>
          </nav>
        </div>
    </header>
</template>

<script>
    export default {
        name: 'Header'
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    a {
        text-decoration: none;
    }
</style>
